/* src/components/Content/Pricing.module.css */
.pricingSection {
  padding: 40px 20px;
  text-align: center;
  background-color: #fff; /* Light background to differentiate section */
}

.pricingGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between cards */
}

.pricingCard {
  display: flex;
  flex-direction: column; /* Arrange children vertically */
  justify-content: center; /* Center children vertically */
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative; /* Allows positioning of content over background */
  overflow: hidden; /* Ensure the background logo does not overflow */
  min-height: 120px;
}

.backgroundLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1; /* Apply faded effect only to background layer */
  z-index: 0; /* Place the background layer behind the content */
}

.pricingCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.serviceName {
  font-size: 1.2rem;
  margin-bottom: auto; /* Allow name to stay at the top */
  color: #000;
}

.servicePrice {
  font-size: 2rem; /* Increase font size of the price */
  font-weight: bold;
  color: #000; /* Bold black for emphasis */
  margin: 10px 0; /* Add spacing around the price */
  align-self: center; /* Center price horizontally in the card */
  z-index: 1; /* Ensure text is above the background */
}

/* Global subheading style */
.subheading {
  font-size: 2rem; /* Large font size */
  font-weight: bold;
  color: #555;
  line-height: 1; /* Adjust line-height to control spacing between lines */
  text-align: left; /* Align text to the left */
}

@media (max-width: 1600px) {
  .subheading {
    padding-top: 20px;
    font-size: 1.5rem; /* Large font size */
    padding-left: 10px;
  }

  .pricingSection{
    padding: 0;
  }
}
