/* src/components/Content/Contact.module.css */
.contactSection {
    background-color: #ffff; /* Light background color */
  }
  
  .contactList {
    list-style: none; /* Remove default list bullets */
    padding: 0;
    margin: 15px;
  }
  
  .contactItem {
    display: flex; /* Use Flexbox for alignment */
    align-items: center; /* Vertically centers the content */
    padding: 10px;
    margin: 5px 0;
    background: #fff;
    border-left: 4px solid #555; /* Colored border for emphasis */
    border-radius: 4px; /* Slight rounding */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Light shadow for lift */
  }
  
  .contactItem:hover {
    background: #f0f0f0; /* Change background on hover */
    border-left: 4px solid #47bfff; /* Darken border on hover */
    color: #0077b5;
  }
  
  .icon {
    font-size: 1.5rem; /* Adjust the size of the icons */
    margin-right: 10px; /* Space between icon and text */
    color: #555; /* Initial color of the icon */
    flex-shrink: 0; /* Prevent the icon from shrinking */
  }
  
  .contactItem span {
    line-height: 1.2; /* Ensure line-height is not too large */
    display: inline-block; /* Make sure the text stays on the baseline */
  }
  
  .contactItem:hover .icon {
    color: #47bfff; /* Change icon color on hover */
  }

/* Make this global style */
.subheading {
    font-size: 2rem; /* Large font size */
    font-weight: bold;
    color: #555;
    line-height: 1; /* Adjust line-height to control spacing between lines */
    text-align: left; /* Align text to the left */
    margin-left: 20px;
}

.contactLink {
    text-decoration: none; /* Remove default link underline */
    color: #000;
}
  
@media (max-width: 1600px) {
  .subheading {
    padding-top: 20px;
    padding-left: 10px;
    font-size: 1.5rem; /* Large font size */
    margin-left: 0px;
  }

}   
  