/* src/components/Content/Content.module.css */
.content {
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  padding: 0 20px; /* Reduced top padding for a more compact layout */
  box-sizing: border-box;
  position: relative; /* Position relative for absolute positioning of elements inside */
}

/* Mobile styles - start stacking at 1600px */
@media (max-width: 1600px) {
  .content {
    width: 100%; /* Make content full width on mobile */
    height: auto; /* Adjust height for mobile */
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Logo on the left, button in the center */
  position: relative; /* Positioned relative to the content container */
  padding: 20px 20px; /* Reduce padding to make the header more compact */
}

.logo {
  width: 160px; /* Adjust size to make it larger */
  height: auto; /* Maintain aspect ratio */
  margin-right: auto; /* Push logo to the far left */
}

.bookNowButton {
  background-color: #555; /* Primary color */
  color: white;
  border: none;
  width: 50%; /* Button width on desktop */
  padding: 15px 30px; /* Increase button size */
  border-radius: 5px;
  font-size: 1.2rem; /* Increase font size */
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: auto; /* Keep button positioned centrally */
  animation: pulseDesktop 8s infinite; /* Add pulsing animation for desktop */
}

.bookNowButton:hover {
  background-color: #47bfff; /* Hover effect */
}

/* Mobile styles - fix the button with adjusted width and position */
@media (max-width: 1600px) {
  .bookNowButton {
    position: fixed; /* Fix the button at the bottom of the screen */
    bottom: 20px; /* Float slightly above the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust positioning from center */
    width: 70%; /* Set the button width to 75% of the screen */
    padding: 12px; /* Adjust padding for mobile */
    font-size: 1.5rem; /* Increase font size slightly for better visibility */
    z-index: 100; /* High z-index to stay above other content */
    border-radius: 10px; /* Add border radius for a floating appearance */
    animation: pulse 8s infinite; /* Use mobile-specific pulsing animation */
  }

  .separator {
    margin-top: 20px;
  }

  .logo {
    display: none;
  }

  .header {
    padding: 0;
  }

  .footerContainer{
    margin-bottom: 80px;
  }
}

/* Keyframes for pulsing animation on mobile */
@keyframes pulse {
  80% {
    transform: translateX(-50%) scale(1); /* Start at normal size */
  }
  90% {
    transform: translateX(-50%) scale(1.1); /* Scale up slightly */
  }
  100% {
    transform: translateX(-50%) scale(1); /* Return to normal size */
  }
}

/* Keyframes for pulsing animation on desktop */
@keyframes pulseDesktop {
  80% {
    transform: scale(1); /* Start at normal size */
  }
  90% {
    transform: scale(1.1); /* Scale up slightly */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.separator {
  border: none; /* Remove default border */
  border-top: 2px solid #ddd; /* Add a top border to create the line */
}

.workList {
  list-style: none;
  padding: 0;
}

.workList li {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.footerContainer {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  padding: 20px 0; /* Padding for footer */
  width: 100%;
}
