/* src/App.module.css */
.app {
  display: flex;
  flex-direction: row; /* Keep slider and content side by side on desktop */
  height: 100vh;
}

.main {
  display: flex;
  width: 100%;
}

/* Mobile styles - start stacking at 1600px */
@media (max-width: 1600px) {
  .app {
    flex-direction: column; /* Stack slider on top of content */
  }

  .main {
    flex-direction: column; /* Ensure both slider and content take full width */
  }
}
