/* src/components/ImageSlider/ImageSlider.module.css */
.sliderContainer {
  width: 50%;
  height: 100vh;
  position: relative; /* Allows positioning of overlay elements */
  overflow: hidden;
}

/* Mobile styles - start stacking at 1600px */
@media (max-width: 1600px) {
  .sliderContainer {
    width: 100%; /* Make slider full width on mobile */
    height: 33vh; /* Adjust height for mobile */
  }
}

.slide {
  height: 100%;
}

.image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Mobile styles - start stacking at 1600px */
@media (max-width: 1600px) {
  .image {
    height: 33vh; /* Adjust image height for mobile */
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start; /* Aligns content to the left */
  align-items: flex-start; /* Aligns content to the top */
  padding: 0; /* Remove any padding to start directly from the edge */
  z-index: 10; /* Place above the slider */
}

.overlayContent {
  position: absolute; /* Absolute positioning to control exact placement */
  top: 20px; /* Adjust as needed to set distance from the top */
  left: 20px; /* Adjust as needed to set distance from the left */
  display: flex;
  flex-direction: column; /* Stack text and logo vertically */
  align-items: flex-start; /* Aligns the content to the left */
}

.overlayText {
  font-size: 6rem; /* Large font size for desktop */
  font-weight: bold;
  color: #ffffff; /* White color */
  margin: 20px;
  line-height: 1; /* Adjust line-height to control spacing between lines */
  text-align: left; /* Align text to the left */
}

/* Mobile styles - adjust text size */
@media (max-width: 1600px) {
  .overlayText {
    font-size: 5rem; /* Smaller font size for mobile */
    margin: 10px; /* Reduce margins for better fit */
  }
}

/* Mobile styles - adjust text size */
@media (max-width: 1000px) {
  .overlayText {
    font-size: 4rem; /* Smaller font size for mobile */
  }
}


/* Mobile styles - adjust text size */
@media (max-width: 550px) {
  .overlayText {
    font-size: 3rem; /* Smaller font size for mobile */
  }
}
