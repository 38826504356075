/* Map.module.css */

.mapContainer {
    height: 200px;
  }

.map{
  width: auto;
  height: 300px;

}
  
/* Make this global style */
.subheading {
  padding-left: 20px;
  font-size: 2rem; /* Large font size */
  font-weight: bold;
  color: #555;
  line-height: 1; /* Adjust line-height to control spacing between lines */
  text-align: left; /* Align text to the left */
  
}

.subsubheading{
  padding-left: 20px;
  font-size: 1.5rem; /* Large font size */
  font-weight: bold;
  color: #555;
  line-height: 1; /* Adjust line-height to control spacing between lines */
  text-align: left; /* Align text to the left */
}

.parkingList {
  list-style: none; /* Remove default list bullets */
  padding: 0;
  margin: 0;
}

.parkingList li {
  padding: 10px;
  margin: 5px 15px;
  background: #fff;
  border-left: 4px solid #555;; /* Colored border for emphasis */
  border-radius: 4px; /* Slight rounding */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Light shadow for lift */
}

@media (max-width: 1600px) {
  .subheading {
    padding-left: 10px;
    font-size: 1.5rem; /* Large font size */
  }
  
  .subsubheading{
    padding-left: 10px;
    font-size: 1rem; /* Large font size */
  }
}